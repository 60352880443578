.switch {
  display: flex;
  gap: 8px;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }

  & :global(.MuiSwitch-root) {
    width: 32px;
    height: 16px;
    border-radius: 22px;
    padding: 0;

    & :global(.MuiSwitch-track) {
      border-radius: 13px;
      background-color: var(--color-gray-700);
      opacity: 1;
    }

    & :global(.MuiSwitch-switchBase) {
      padding: 0;
      margin: 2px 3px;
      transition-duration: 300ms;

      & :global(.MuiSwitch-thumb) {
        box-sizing: border-box;
        width: 12px;
        height: 12px;
        color: var(--color-white);
        box-shadow: 0 1px 2px 0 rgb(40 39 50 / 18%);
      }

      &:global(.Mui-disabled) :global(.MuiSwitch-thumb) {
        color: var(--color-gray-500);
        box-shadow: 0 0 16px 0 rgb(40 39 50 / 18%);
      }

      &:global(.Mui-checked) {
        transform: translateX(14px);
        color: var(--color-white);

        & + :global(.MuiSwitch-track) {
          background-color: var(--color-blue-500);
          opacity: 1;
          border: 0;
        }

        &:global(.Mui-disabled) + :global(.MuiSwitch-track) {
          opacity: 1;
          background-color: var(--color-gray-400);
        }

        &:global(.Mui-disabled) :global(.MuiSwitch-thumb) {
          color: var(--color-gray-100);
          box-shadow: 0 1px 2px 0 rgb(40 39 50 / 18%);
        }
      }

      &:global(.Mui-focusVisible) :global(.MuiSwitch-thumb) {
        color: var(--color-blue-500);
        border: 6px solid var(--color-white);
        border-radius: 22px;
        box-shadow: 0 1px 2px 0 rgb(40 39 50 / 18%);
      }
    }

    & :global(.Mui-disabled) + :global(.MuiSwitch-track) {
      background-color: var(--color-gray-400);
      opacity: 1;
    }
  }
}

.label.disabled {
  color: var(--color-gray-800);
  cursor: default;
}
