import { Map, List, Set, fromJS } from 'immutable';
import { append, hasPath, path, assocPath, isNil } from 'ramda';
import { PurchaseRequestStatuses } from '../components/PurchaseRequests/entities/PurchaseRequest';
import { ProductsErrorsReasons } from '../components/PurchaseRequests/common/ProductsTable.constants';
import { isNilOrEmpty } from '../utils/utils';
import {
  ACCEPTED_RESPONSE_STATUSES,
  PENDING_RESPONSE_STATUSES,
  rejected
} from '../presenters/sidebars/PurchaseRequestResponsesSidebar/status';

function isSelectedProductBySupplier(product) {
  return Number(product.price) > 0 && Number(product.count > 0);
}

const setRequests = (
  data,
  {
    requests = [],
    publishedRequests = [],
    size,
    limit,
    offset,
    search,
    order,
    direction,
    selectedCategoriesId = [],
    orderBy,
    filterApplied,
    showMode = [],
    showHint
  }
) =>
  data
    .set('requests', fromJS(requests))
    .set('publishedRequests', fromJS(publishedRequests))
    .set('size', size)
    .set('limit', limit)
    .set('offset', offset)
    .set('search', search)
    .set('order', order)
    .set('orderBy', orderBy)
    .set('selectedCategoriesId', Set(selectedCategoriesId))
    .set('direction', direction)
    .set('filterApplied', filterApplied)
    .set('showMode', showMode)
    .set('showHint', showHint);

const setAllRequestsSavedFilters = (data, payload) =>
  data.set('savedFilters', payload);

const setAllRequestsActiveFilters = (data, payload) =>
  data.set('activeFilters', payload);

const setRequest = (data, { request }) => data.set('request', fromJS(request));
const saveNewRequest = (data, newRequest) =>
  data.set('newRequest', fromJS(newRequest));

const addNewRequestAttachments = (data, attachments) => {
  if (!data.get('newRequest')) {
    return data.set('newRequest', fromJS({ attachments }));
  }
  return data.update('newRequest', (newRequest) => {
    const newAttachments = newRequest.get('attachments').concat(attachments);
    return newRequest.set('attachments', newAttachments);
  });
};

const deleteRequest = (data) => data.set('request', new Map());
const deleteNewRequest = (data) => data.remove('newRequest');
const deleteMyRequest = (data, { id }) =>
  data
    .update('requests', (requests) =>
      requests.filter((i) => i.get('id') !== id)
    )
    .update('size', (size) => size - 1);

const setRequestStatus = (
  data,
  { id, status, finalizeAt, responseEndDate }
) => {
  let newData = data;
  if (data.get('request')) {
    newData = newData.setIn(['request', 'status'], status);

    if (finalizeAt) {
      newData = newData.setIn(['request', 'finalizeAt'], finalizeAt);
    }

    if (responseEndDate) {
      newData = newData.setIn(['request', 'responseEndDate'], responseEndDate);
    }
  }
  return newData.update('requests', (requests) => {
    const index = requests.findIndex((i) => i.get('id') === id);
    let updatedRequests = requests.setIn([index, 'status'], status);

    if (finalizeAt) {
      updatedRequests = updatedRequests.setIn(
        [index, 'finalizeAt'],
        finalizeAt
      );
    }

    if (responseEndDate) {
      updatedRequests = updatedRequests.setIn(
        [index, 'responseEndDate'],
        responseEndDate
      );
    }

    return updatedRequests;
  });
};

const setExecutorStatus = (data, { executorEmployeeId, requestId, status }) =>
  data.update('request', (request) => {
    if (request.get('id') !== requestId) return request;

    return request.update('executors', (executors) => {
      const index = executors.findIndex(
        (i) => i.get('employeeId') === executorEmployeeId
      );

      if (index === -1) return executors;

      return executors.setIn([index, 'status'], status);
    });
  });

const setUnpublished = (data, id) =>
  data.getIn(['request', 'id']) === id
    ? data.setIn(
        ['request', 'status'],
        PurchaseRequestStatuses.receptionCompleted
      )
    : data;
const setCompleted = (data, id) =>
  data.getIn(['request', 'id']) === id
    ? data.setIn(['request', 'status'], PurchaseRequestStatuses.completed)
    : data;

const setFeedback = (data, { requestId, responseId, feedbackId, rating }) => {
  if (data.getIn(['request', 'id']) === requestId) {
    const responses = data.getIn(['request', 'responses']).toJS();
    const responseToUpdate = responses.find((res) => res.id === responseId);
    const otherResponses = responses.filter((res) => res.id !== responseId);

    const companyInfo = {
      ...responseToUpdate.companyInfo,
      rating
    };

    const updatedResponse = {
      ...responseToUpdate,
      companyInfo,
      feedbackId
    };

    const requests = [updatedResponse, ...otherResponses];

    return data.setIn(['request', 'responses'], fromJS(requests));
  }

  return data;
};

const setCancelled = (data, id) =>
  data.getIn(['request', 'id']) === id
    ? data.setIn(['request', 'status'], PurchaseRequestStatuses.canceled)
    : data;
const setResponded = (data, responseParam) =>
  data.updateIn(['request', 'responded'], (respond) => {
    const response = fromJS(responseParam);
    return respond ? respond.merge(response) : response;
  });
const setInvoicedStatus = (data, responseParam) =>
  data.updateIn(['request', 'responded'], (respond) => {
    const response = fromJS({
      status: responseParam.status,
      invoice: responseParam.invoice
    });
    return respond ? respond.merge(response) : response;
  });
const removeResponded = (data) => data.setIn(['request', 'responded'], null);
const setResponsibles = (data, { responsibles }) =>
  data.set('responsibles', fromJS(responsibles));
const setAllResponses = (data, { list: responses, size }) =>
  data.set('allResponses', fromJS(responses)).set('size', size);
const setResponses = (data, { responses }) =>
  data.set('responses', fromJS(responses));
const clearAllResponses = (data) => data.set('allResponses', List());

const acceptResponse = (data, { requestId, responseId }) => {
  let newData = data;

  if (newData.get('response').size) {
    newData = newData.setIn(['response', 'status'], 'confirmed');
  }

  if (newData.get('allResponses').size) {
    newData = newData.update('allResponses', (responses) => {
      const isResponseToCurrentRequest = (response) =>
        response.get('requestId') === requestId ||
        response.get('attrs.purchaseRequest.id') === requestId;

      const isAcceptedResponse = (response) =>
        response.get('id') === responseId;

      const choiceStatus = (response) =>
        isAcceptedResponse(response) ? 'confirmed' : 'rejected';

      return responses.map((response) => {
        if (isResponseToCurrentRequest(response)) {
          return response.set('status', choiceStatus(response));
        }

        return response;
      });
    });
  }

  return newData;
};

const rejectResponse = (data, { responseId }) => {
  let newData = data;

  if (newData.get('response').size) {
    newData = newData.setIn(['response', 'status'], 'rejected');
  }

  if (newData.get('allResponses').size) {
    newData = newData.update('allResponses', (responses) => {
      const index = responses.findIndex((i) => i.get('id') === responseId);
      return responses.setIn([index, 'status'], 'rejected');
    });
  }

  return newData;
};

const setResponseStatus = (data, { responseId, status }) =>
  data.updateIn(['request', 'responses'], (responses) => {
    if (isNil(responses)) return responses;

    const index = responses.findIndex((i) => i.get('id') === responseId);

    if (index === -1) return responses;

    return responses.setIn([index, 'status'], status);
  });

const setCountries = (data, { countries }) =>
  data.set('countries', fromJS(countries));

const setArchive = (state, { isArchived }) =>
  state.setIn(['request', 'isArchived'], isArchived);

const setArchiveRespond = (state, { isArchived }) =>
  state.setIn(['request', 'responded', 'isArchived'], isArchived);

const setRegionsCount = (data, { regionsCount }) =>
  data.set('regionsCount', fromJS(regionsCount));
const setRegions = (data, { regions }) => data.set('regions', fromJS(regions));
const setCities = (data, { cities }) => data.set('cities', fromJS(cities));

const setResponseConfirmed = (data, { requestId }) =>
  data.update('responses', (responses) => {
    const index = responses.findIndex((i) => i.get('id') === requestId);
    return responses.setIn([index, 'status'], 'confirmed');
  });
const setResponseRejected = (data, { requestId }) =>
  data.update('responses', (responses) => {
    const index = responses.findIndex((i) => i.get('id') === requestId);
    return responses.setIn([index, 'status'], 'rejected');
  });

const setIsBookmarked = (data, { id, isBookmarked = false }) =>
  data.update('publishedRequests', (publishedRequests) => {
    const index = publishedRequests.findIndex((i) => i.get('id') === id);
    return publishedRequests.setIn([index, 'isBookmarked'], isBookmarked);
  });

const setStatusOfPublishedRequests = (
  data,
  { id, status, finalizeAt, responseEndDate }
) =>
  data.update('publishedRequests', (publishedRequests) => {
    const index = publishedRequests.findIndex((i) => i.get('id') === id);

    let updatedRequests = publishedRequests.setIn([index, 'status'], status);

    if (finalizeAt) {
      updatedRequests = updatedRequests.setIn(
        [index, 'finalizeAt'],
        finalizeAt
      );
    }

    if (responseEndDate) {
      updatedRequests = updatedRequests.setIn(
        [index, 'responseEndDate'],
        responseEndDate
      );
    }

    return updatedRequests;
  });
const purgeRegion = (data) => data.set('regions', List());
const purgeRequest = (data) => data.set('request', Map());

const getList = (unsentComments, requestId, employeeId) =>
  path([employeeId, requestId, 'list'], unsentComments);
const hasList = (unsentComments, requestId, employeeId) =>
  hasPath([employeeId, requestId, 'list'], unsentComments);
const setToList = ({ unsentComments, requestId, employeeId, data }) =>
  assocPath(
    [employeeId.toString(), requestId.toString(), 'list'],
    data,
    unsentComments
  );

export const addOrUpdateUnsentRequestComment = (
  data,
  { employeeId, comment }
) => {
  const { requestId } = comment;
  const unsentComments = data.get('unsentComments')?.toJS() ?? [];

  if (hasList(unsentComments, requestId, employeeId)) {
    const list = getList(unsentComments, requestId, employeeId);
    const newList = append(comment, list);
    const newUnsentState = setToList({
      unsentComments,
      requestId,
      employeeId,
      data: newList
    });
    return data.set('unsentComments', fromJS(newUnsentState));
  }
  const newUnsentState = setToList({
    unsentComments,
    requestId,
    employeeId,
    data: [comment]
  });
  return data.set('unsentComments', fromJS(newUnsentState));
};

export const removeUnsentRequestComment = (
  data,
  { employeeId, requestId, commentId }
) => {
  const unsentComments = data.get('unsentComments')?.toJS() ?? [];
  const list = getList(unsentComments, requestId, employeeId);
  if (!list) return data;

  const newList = list.filter((m) => m.id !== commentId);
  const newUnsentState = setToList({
    unsentComments,
    requestId,
    employeeId,
    data: newList
  });
  return data.set('unsentComments', fromJS(newUnsentState));
};

const setResponse = (data, { response }) =>
  data.set('response', fromJS(response));
const purgeResponse = (data) => data.set('response', Map());

const setSearch = (data, search) => data.set('search', search);

const setSuppliers = (data, { suppliers }) =>
  data.set('suppliers', fromJS(suppliers));

const setError = (data, { error }) => data.set('error', fromJS(error));

const updateRespond = (data, { response, manualProposal }) =>
  data.updateIn(['request', 'responses'], (responses) => {
    const index = responses?.findIndex(
      (i) =>
        i.get('id') === response.id &&
        !!i.get('manualProposal') === !!manualProposal
    );

    if (!isNil(index) && index !== -1) {
      const currentResponse = responses.get(index);

      return responses.set(index, currentResponse.merge(fromJS(response)));
    }

    return responses;
  });

const removeRespond = (data, { proposalId, manualProposal }) =>
  data.updateIn(
    ['request', 'responses'],
    (responses) =>
      responses?.filter(
        (i) =>
          !(
            i.get('id') === proposalId &&
            !!i.get('manualProposal') === !!manualProposal
          )
      ) ?? responses
  );

const bindProducts = (state, payload) => {
  const { requestId, products } = payload;
  const updatedState = state;

  if (state.getIn(['request', 'id']) !== requestId) return state;

  if (!state.getIn(['request', 'products'])?.size) return state;

  const updatedRequestProducts = state
    .getIn(['request', 'products'])
    .map((product, index) => {
      if (!products[index].responses?.length) return product;

      return product.set('response', fromJS(products[index].responses[0]));
    });

  function updateResponses(responses) {
    return responses.map((response) =>
      response.updateIn(
        ['attrs', 'purchaseRequest', 'products'],
        (responseProducts) =>
          responseProducts.map((product, productIndex) => {
            const newProduct = products[productIndex];
            const acceptedResponse = updatedRequestProducts
              .getIn([productIndex, 'response'])
              ?.toJS();

            if (!isSelectedProductBySupplier(product.toJS())) {
              return product.set(
                'response',
                fromJS({
                  canBeSelected: false,
                  reason: ProductsErrorsReasons.NotSelectedBySupplier
                })
              );
            }

            if (
              !isNilOrEmpty(acceptedResponse) &&
              acceptedResponse.id !== response.get('id')
            ) {
              return product.set(
                'response',
                fromJS({
                  canBeSelected: false,
                  reason: ProductsErrorsReasons.AnotherSupplierSelected,
                  companyName: newProduct.responses[0].companyName
                })
              );
            }

            if (
              isNilOrEmpty(acceptedResponse) &&
              ACCEPTED_RESPONSE_STATUSES.includes(response.get('status'))
            ) {
              return product.set(
                'response',
                fromJS({
                  canBeSelected: false,
                  reason: ProductsErrorsReasons.RejectedByCustomer
                })
              );
            }

            return product;
          })
      )
    );
  }

  return updatedState
    .setIn(['request', 'products'], updatedRequestProducts)
    .updateIn(['request', 'responses'], updateResponses);
};

const rejectAllPendingResponses = (state, payload) => {
  const { requestId } = payload;

  if (state.getIn(['request', 'id']) !== requestId) return state;

  return state.updateIn(['request', 'responses'], (responses) =>
    responses.map((response) =>
      PENDING_RESPONSE_STATUSES.includes(response.get('status'))
        ? response.set('status', rejected)
        : response
    )
  );
};

const setAuctionFinishedToAllResponses = (state, payload) => {
  const { requestId } = payload;

  if (state.getIn(['request', 'id']) !== requestId) return state;

  return state.updateIn(['request', 'responses'], (responses) =>
    responses.map((response) =>
      response.setIn(['attrs', 'purchaseRequest', 'isAuctionFinished'], true)
    )
  );
};

const setResponseError = (state, { productId, error }) =>
  state.setIn(['responseError', productId], error);

const clearResponseError = (state, { productId, errors }) => {
  const currentError = state.getIn(['responseError', productId]);

  if (!errors?.includes(currentError)) {
    return state;
  }

  return state.setIn(['responseError', productId], null);
};

const setAuctionIsFinished = (state, payload) => {
  const { requestId } = payload;

  if (state.getIn(['request', 'id']) !== requestId) return state;

  return state.setIn(['request', 'auctionInfo', 'isFinished'], true);
};

const defaultState = Map({
  requests: List(),
  request: Map(),
  publishedRequests: List(),
  selectedCategoriesId: Set(),
  regions: List(),
  responses: List(),
  response: Map(),
  allResponses: List(),
  suppliers: List(),
  comments: List(),
  commentsTopics: List(),
  unsentComments:
    fromJS(JSON.parse(localStorage.getItem('unsentRequestComments'))) ||
    new Map(),
  showMode: Set(),
  error: Map(),
  responseError: Map()
});

const reset = () => defaultState;

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case 'PURCHASE_REQUEST:GET_REQUESTS:REQUEST':
      return setRequests(state, payload);
    case 'PURCHASE_REQUEST:GET_REQUESTS:SUCCESS':
      return setRequests(state, payload);
    case 'PURCHASE_REQUEST:SAVED_FILTERS:SET':
      return setAllRequestsSavedFilters(state, payload);
    case 'PURCHASE_REQUEST:ACTIVE_FILTERS:SET':
      return setAllRequestsActiveFilters(state, payload);
    case 'PURCHASE_REQUEST:SET_REQUESTS_SEARCH':
      return setSearch(state, payload);
    case 'PURCHASE_REQUEST:SAVE_TO_NEW_REQUEST':
      return saveNewRequest(state, payload);
    case 'PURCHASE_REQUEST:RESET':
      return reset();
    case 'PURCHASE_REQUEST:SET_IS_BOOKMARKED':
      return setIsBookmarked(state, payload);
    case 'PURCHASE_REQUEST:ADD_ATTACH_TO_NEW_REQUEST':
      return addNewRequestAttachments(state, payload);
    case 'PURCHASE_REQUEST:DELETE_SAVED_REQUEST':
      return deleteNewRequest(state);
    case 'PURCHASE_REQUEST:GET_REQUEST:SUCCESS':
    case 'PURCHASE_REQUEST:CREATE_REQUEST:SUCCESS':
    case 'PURCHASE_REQUEST:EDIT:SUCCESS':
      return setRequest(state, payload);
    case 'PURCHASE_REQUEST:GET_REQUEST:FAILURE':
      return setError(state, payload);
    case 'PURCHASE_REQUEST:DELETE_REQUEST':
      return deleteRequest(state);
    case 'PURCHASE_REQUEST:CHANGE_STATUS':
      return setRequestStatus(state, payload);
    case 'PURCHASE_REQUEST:CHANGE_STATUS_OF_PUBLISHED_REQUEST':
      return setStatusOfPublishedRequests(state, payload);
    case 'PURCHASE_REQUEST:UPDATE_EXECUTOR_STATUS':
      return setExecutorStatus(state, payload);
    case 'PURCHASE_REQUEST:RESPOND:SUCCESS':
      return setResponded(state, payload);
    case 'PURCHASE_REQUEST:SEND_INVOICED:SUCCESS':
      return setInvoicedStatus(state, payload);
    case 'PURCHASE_REQUEST:UNPUBLISH:SUCCESS':
      return setUnpublished(state, payload);
    case 'PURCHASE_REQUEST:COMPLETE:SUCCESS':
      return setCompleted(state, payload);
    case 'PURCHASE_REQUEST:FEEDBACK':
      return setFeedback(state, payload);
    case 'PURCHASE_REQUEST:CANCEL:SUCCESS':
      return setCancelled(state, payload);
    case 'PURCHASE_REQUEST:BIND_PRODUCTS':
      return bindProducts(state, payload);
    case 'PURCHASE_REQUEST:REJECT_ALL_PENDING_RESPONSES':
      return rejectAllPendingResponses(state, payload);
    case 'PURCHASE_REQUEST:SET_AUCTION_FINISHED_TO_ALL_RESPONSES':
      return setAuctionFinishedToAllResponses(state, payload);
    case 'PURCHASE_REQUEST:SET_AUCTION_IS_FINISHED':
      return setAuctionIsFinished(state, payload);
    case 'PURCHASE_REQUEST:RESPOND:DELETE':
      return removeResponded(state);
    case 'PURCHASE_REQUEST:DELETE_REQUEST:SUCCESS':
      return deleteMyRequest(state, payload);
    case 'PURCHASE_REQUEST:GET_RESPONSIBLES:SUCCESS':
      return setResponsibles(state, payload);
    case 'PURCHASE_REQUEST:GET_COUNTRIES:SUCCESS':
      return setCountries(state, payload);
    case 'PURCHASE_REQUEST:GET_REGIONS_COUNT:SUCCESS':
      return setRegionsCount(state, payload);
    case 'PURCHASE_REQUEST:GET_REGIONS:SUCCESS':
      return setRegions(state, payload);
    case 'PURCHASE_REQUEST:GET_CITIES:SUCCESS':
      return setCities(state, payload);
    case 'PURCHASE_REQUEST:RESPONSES':
      return setResponses(state, payload);
    case 'PURCHASE_REQUEST:ALL_RESPONSES':
      return setAllResponses(state, payload);
    case 'PURCHASE_REQUEST:GET_ALL_SUPPLIERS_OF_MY_REQUESTS':
      return setSuppliers(state, payload);
    case 'PURCHASE_REQUEST:ALL_RESPONSES_CLEAR':
      return clearAllResponses(state);
    case 'PURCHASE_REQUEST:GET_RESPONSE_BY_ID':
      return setResponse(state, payload);
    case 'PURCHASE_REQUEST:PURGE_RESPONSE':
      return purgeResponse(state);
    case 'PURCHASE_REQUEST:ACCEPT_RESPONSE':
      return acceptResponse(state, payload);
    case 'PURCHASE_REQUEST:REFUSE_RESPONSE':
      return rejectResponse(state, payload);
    case 'PURCHASE_REQUEST:SET_RESPONSE_STATUS':
      return setResponseStatus(state, payload);
    case 'PURCHASE_REQUEST:CONFIRM_SUPPLIER':
      return setResponseConfirmed(state, payload);
    case 'PURCHASE_REQUEST:REJECT_SUPPLIER':
      return setResponseRejected(state, payload);
    case 'PURCHASE_REQUEST:PURGE_REGION':
      return purgeRegion(state);
    case 'PURCHASE_REQUEST:UPDATE_REQUEST_RESPOND':
      return updateRespond(state, payload);
    case 'PURCHASE_REQUEST:REMOVE_REQUEST_RESPOND':
      return removeRespond(state, payload);
    case 'PURCHASE_REQUEST:PURGE_REQUEST':
      return purgeRequest(state);
    case 'PURCHASE_REQUEST:SET_ARCHIVE':
      return setArchive(state, payload);
    case 'PURCHASE_REQUEST:SET_ARCHIVE_RESPOND':
      return setArchiveRespond(state, payload);
    case 'PURCHASE_REQUEST:SET_RESPONSE_ERROR':
      return setResponseError(state, payload);
    case 'PURCHASE_REQUEST:CLEAR_RESPONSE_ERROR':
      return clearResponseError(state, payload);
    default:
      return state;
  }
};
