.loader {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--bg-content);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.dots {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;

  .dot {
    border: 0;
    margin: 0;
    width: 40%;
    height: 40%;
    position: absolute;
    border-radius: 50%;
    animation: spin 2s ease infinite;

    &:nth-child(1) {
      background: #1c50de;
      animation-delay: -1.5s;
    }

    &:nth-child(2) {
      background: #b9bfcf;
      animation-delay: -1s;
    }

    &:nth-child(3) {
      background: #a7beff;
      animation-delay: -0.5s;
    }

    &:nth-child(4) {
      background: #7f869e;
    }
  }
}

@keyframes spin {
  0%,
  100% {
    transform: translate(0);
  }

  25% {
    transform: translate(160%);
  }

  50% {
    transform: translate(160%, 160%);
  }

  75% {
    transform: translate(0, 160%);
  }
}
