import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Loader } from '@link/react-components';

const PageLoader = ({ title = 'Istock.Link' }) => (
  <Wrapper>
    <Helmet title={title}>
      <meta name="robots" content="noindex" />
    </Helmet>
    <Loader />
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
`;

export default PageLoader;
