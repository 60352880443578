/* eslint-disable react/no-children-prop */
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Loader, ToastContainer } from '@link/react-components';
import { useAutoLockZoomWhenControlIsFocused } from '@/useAutoLockZoomWhenControlIsFocused';
import 'react-select/dist/react-select.css';
import 'bootstrap/dist/css/bootstrap.css';
import './css/common.styl';
import './css/MainContentStyles.css';
import 'react-components/src/styles/components.css';
import { ThemeProvider } from '@/themeProvider';
// eslint-disable-next-line import/no-named-as-default
import GlobalStyles from '@/styledComponents/globalStyles';
import fetchIntercept from 'fetch-intercept';

import { Interceptor } from '@/Interceptor';
import { usePermissions } from '@/modules/permissions';
import { useLocation, useNavigate, useParams } from '@/hooks';
import { NotificationsPage } from '@/pages/messenger/notifications';
import { SettingsPage } from '@/pages/settings';
import { SnackbarProvider } from '@/shared/components/Snackbar';
import { PopupRoot } from '@/components/popups';
import i18n from '@/config/i18n';
import { Root } from '@/root';
import { NotificationProvider } from '@/components/notification';
import { MarketplacePages } from '@/modules/marketplace/pages';
import LocalProvider from './localProvider';
import CatchErrors from './CatchErrors';
import ConnectionsWrapper from './ConnectionsWrapper';
import { getIsUserUploaded, isUserNotHaveCompany } from './storeGetters';
import * as Breadcrumbs from './components/Breadcrumbs/constants/Breadcrumbs';
import 'whatwg-fetch';
import {
  MainFeedbackFrom,
  MainComingSoon,
  MainNotPermission
} from './components/Main';
import { MessengerResetPassPerfrom } from './pages/MessengerResetPassPerform';
import { MessengerVerifyEmail } from './pages/MessengerVerifyEmail';
import { MessengerInvalidLink } from './pages/MessengerInvalidLink';
import { RegistrationPage } from './pages/registrationPage';
import { Employees } from './pages/Employees';
import { ProfileEdit } from './pages/ProfileEdit';
import { FileStoragePage } from './pages/FileStorage';
import { ClearDataPage } from './pages/ClearData';
import { PartnerInvite } from './pages/PartnerInvite';
import { FileStorageFileInfo } from './pages/FileStorageFileInfo';
import { PurchaseRequest } from './pages/PurchaseRequest';
import { ProcurementWithMyProposal } from './pages/ProcurementWithMyProposal';
import { AllPurchaseRequests } from './pages/AllPurchaseRequest';
import { EditPurchaseRequest } from './pages/EditPurchaseRequest';
import { CreatePurchaseRequest } from './pages/CreatePurchaseRequest';
import { MyPurchaseRequests } from './pages/MyPurchaseRequests';
import { MyResponsesPage } from './pages/MyResponses';
import { ResponsePurchaseRequest } from './pages/ResponsePurchaseRequest';
import { MessengerPage } from './pages/MessengerPage';
import { RedirectPage } from './components/ChatWidget/modules/redirectPage';
import { CompanyProfilePage as NewCompanyProfile } from './pages/messenger/companyProfile';
import { CompareResponses } from './pages/messenger/compareResponses';
import TranslateProvider from './TranslateProvider';
// import TaskRoutes from './tasks-manager/routes';
import { PurchaseRequestShare } from './pages/PurchaseRequestShare';
import VideoChatProvider from './modules/VideoChat/VideoChatProvider';
import { ProtectedRoute } from './components/PrivateRoute';
import { changePartOfPath } from './utils/utils';
import { PurchaseResponse } from './pages/messenger/purchaseResponses/one';
import { ConstructorRouters } from './routes/constructorRouter';
import { WithConfirmationRequestPage } from './pages/withConfirmationRequest';
import { CompaniesRouter } from './routes/companiesRouter';
import { SubscriptionRefusal } from './pages/SubscriptionRefusal';
import { QuickResponseRouter } from './routes/quickResponseRouter';
import { getCurrentLocation } from './utils/location';
import { AdministrationRouter } from './routes/administrationRouter';
import { ConfirmationResponse } from './pages/confirmationResponse';
import { AuditLogPage } from './pages/AuditLog';
import { NoAccessPage } from './pages/NoAccessPage';
import { NotFound } from './pages/notFound';
import { MyOffice } from './pages/MyOffice';
import { NeedsRoutes } from './routes/needsRouter';
import { RoleManagement } from './pages/RoleManagement';
import { AuctionPage } from './pages/AuctionPage';
import { MyRequestView } from './pages/Requests/MyRequestView';
import { Integration } from './pages/integration';
import { Finance } from './pages/Finance';
import { WarehousesRoutes } from './routes/warehouses';
import { CostAccountingRouter } from './routes/costAccountingRouter';
import { toastConfigurator } from './modules/toast';
import { AccreditationRoutes } from './routes/accreditationRouter';
import { QualificationRoutes } from './routes/qualificationRouter';
import { ReleaseNotes } from './pages/ReleaseNotes';
import { SupplierManagement } from './pages/supplierManagment';
import { Tools } from './pages/tools';
import { Partners } from './pages/partners';
import { MyCompany } from './pages/myCompany';
import { LoginPage } from './pages/loginPage';
import { ResetPassPage } from './pages/ResetPassPage';
import {
  CreateManualProposal,
  EditManualProposal
} from './pages/manualProposal';
import { CreatePreBid, EditPreBid } from './pages/preBid';
import {
  ProcurementEditStage,
  ProcurementNewStage,
  AllStages
} from './pages/ProcurementStage';
import { GeneralSettings } from './pages/settings/General';

const Profile = () => (
  <div style={{ width: '100%', height: '100%' }}>
    <Routes>
      <Route path="edit" element={<ProfileEdit />} />
    </Routes>
  </div>
);

const FileStorage = () => (
  <div style={{ width: '100%', height: '100%' }}>
    <Routes>
      <Route path="uploads" element={<FileStoragePage section="uploads" />} />
      <Route path="chat" element={<FileStoragePage section="chat" />} />
      <Route
        path="file/:section/:fileId"
        element={<FileStorageFileInfo section="chat" />}
      />
    </Routes>
  </div>
);

const Share = () => (
  <Routes>
    <Route path="requests/:requestId" element={<PurchaseRequestShare />} />
  </Routes>
);

const Requests = () => {
  const { pathname } = useLocation();
  const { requestId } = useParams();
  const permissions = usePermissions();

  const haveRequestsEditPermissions = permissions.haveRequestsEditPermissions();
  const hasProposalReadPrivilege = permissions.hasProposalReadPrivilege();
  const hasManualProposalRelatedPrivilege =
    permissions.hasManualProposalCreationPrivilege();

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Routes>
        <Route
          path="all/:requestId"
          element={
            <PurchaseRequest parentBreadcrumbs={[Breadcrumbs.AllPurchases]} />
          }
        />
        <Route path="all" element={<Navigate to="/" replace />} />
        <Route
          path="all/my-requests/create"
          element={<CreatePurchaseRequest link="/requests/all" />}
        />
        <Route
          path="my/:requestId/responses/:companyId"
          element={
            <NewCompanyProfile
              name="Responses"
              link={`/requests/my/${requestId}/responses`}
            />
          }
        />
        <Route
          path="my/:requestId/edit"
          element={
            <ProtectedRoute
              isAllowed={haveRequestsEditPermissions}
              redirectPath={changePartOfPath(pathname, 'edit', 'view')}
              children={<EditPurchaseRequest />}
            />
          }
        />
        <Route path="my/:requestId/view" element={<MyRequestView />} />
        <Route
          path="my/:requestId/viewConfirmation"
          element={<WithConfirmationRequestPage />}
        />
        <Route
          path="my/create"
          element={<CreatePurchaseRequest link="/requests/my" />}
        />
        <Route path="my" element={<MyPurchaseRequests />} />
        <Route
          path="responses-purchase-requests/:responseId/request/:requestId"
          element={
            <ProtectedRoute
              isAllowed={hasProposalReadPrivilege}
              children={<ResponsePurchaseRequest />}
            />
          }
        />
        <Route
          path=":requestId/manual-proposal/:proposalId/view"
          element={
            <ProtectedRoute
              isAllowed={hasProposalReadPrivilege}
              children={<ResponsePurchaseRequest />}
            />
          }
        />
        <Route
          path="responses-purchase-requests/:responseId/request/:requestId/confirmation"
          element={<ConfirmationResponse />}
        />
        <Route
          path="responses-purchase-requests/company/:companyId"
          element={
            <NewCompanyProfile
              title="Purchase requests"
              name="Purchase requests"
              link="/requests/my"
            />
          }
        />
        <Route
          path="responses-purchase-requests/my-requests/create"
          element={<CreatePurchaseRequest link="/requests/my" />}
        />
        <Route
          path="customers/:companyId/view"
          element={
            <NewCompanyProfile name="Purchase requests" link="/requests/all" />
          }
        />
        <Route
          path=":requestId/compare-responses"
          element={
            <ProtectedRoute
              isAllowed={hasProposalReadPrivilege}
              children={<CompareResponses />}
            />
          }
        />
        <Route path=":auctionId/auction" element={<AuctionPage />} />
        <Route
          path=":requestId/manual-proposal/create"
          element={
            <ProtectedRoute
              children={<CreateManualProposal />}
              isAllowed={hasManualProposalRelatedPrivilege}
            />
          }
        />
        <Route
          path=":requestId/manual-proposal/:proposalId/edit"
          element={
            <ProtectedRoute
              children={<EditManualProposal />}
              isAllowed={hasManualProposalRelatedPrivilege}
            />
          }
        />
        <Route path=":requestId/pre-bid/create" element={<CreatePreBid />} />
        <Route path=":requestId/pre-bid/edit" element={<EditPreBid />} />
        <Route
          path=":procurementId/procurement-stage/new"
          element={<ProcurementNewStage />}
        />
        <Route
          path=":procurementId/procurement-stage/:stageId/edit"
          element={<ProcurementEditStage />}
        />
        <Route
          path=":procurementId/procurement-stage/all"
          element={<AllStages />}
        />
      </Routes>
    </div>
  );
};

const MyResponses = () => {
  const permissions = usePermissions();

  const haveResponsesReadPermissions =
    permissions.haveResponsesReadPermissions();

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Routes>
        <Route path="/" element={<MyResponsesPage />} />
        <Route
          path="request/:requestId"
          element={<ProcurementWithMyProposal />}
        />
        <Route
          path="request/:requestId/response/:responseId"
          element={
            <PurchaseResponse
              name="Purchase requests"
              link="/requests/my-responses"
            />
          }
        />
        <Route
          path="company/:companyId"
          element={
            <NewCompanyProfile
              title="Purchase requests"
              name="Purchase requests"
              link="/requests/my-responses"
            />
          }
        />
        <Route
          path="my-requests/create"
          link="/requests/my-responses"
          element={
            <ProtectedRoute isAllowed={haveResponsesReadPermissions}>
              <CreatePurchaseRequest link="/requests/my-responses" />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
};

const RoutesComponent = () => {
  const isUserUploaded = useSelector(getIsUserUploaded);
  const isUserWithoutCompany = useSelector(isUserNotHaveCompany);
  const permissions = usePermissions();
  const haveCompanyReadPermissions = permissions.haveCompanyReadPermissions();
  const haveReadPermissionPurchaseRequest =
    permissions.haveRequestsReadPermissions();
  const haveResponsesReadPermissions =
    permissions.haveResponsesReadPermissions();
  const havePermissionToEditRoles = permissions.haveRoleEditPermissions();
  const haveRoutesReadPermissions = permissions.haveRoutesReadPermissions();
  const haveCompaniesReadPermissions =
    permissions.haveCompaniesReadPermissions();
  const haveFinanceReadPermissions = permissions.haveFinanceReadPermissions();
  const haveSettingsManagementPermissions =
    permissions.haveSettingsManagementPermissions();

  const [loading, setLoading] = useState(!isUserUploaded);

  useEffect(() => {
    setLoading(!isUserUploaded);
  }, [isUserUploaded]);

  if (loading) {
    return <Loader />;
  }

  if (isUserWithoutCompany) {
    return (
      <Routes>
        <Route path="/share/*" element={<Share />} />
        <Route
          path="/requests/share/:requestId"
          element={<PurchaseRequestShare />}
        />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/reset-password/perform"
          element={<MessengerResetPassPerfrom />}
        />
        <Route path="/reset-password" element={<ResetPassPage />} />
        <Route path="/verify-email" element={<MessengerVerifyEmail />} />
        <Route path="/invalid-link" element={<MessengerInvalidLink />} />
        <Route path="/registration" element={<RegistrationPage />} />
        <Route path="/registration/:token" element={<RegistrationPage />} />
        <Route path="/feedback" element={<MainFeedbackFrom />} />
        <Route path="/coming-soon" element={<MainComingSoon />} />
        <Route path="/not-permission" element={<MainNotPermission />} />
        <Route path="/clear-data" element={<ClearDataPage />} />
        <Route path="/unsubscribe/:token" element={<SubscriptionRefusal />} />
        <Route path="/quick-response/*" element={<QuickResponseRouter />} />
        <Route path="*" element={<Navigate to="/my-company" replace />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/tools" element={<Tools />} />
      <Route
        path="/tools/constructor/*"
        element={
          <ProtectedRoute isAllowed={haveRoutesReadPermissions}>
            <ConstructorRouters />
          </ProtectedRoute>
        }
      />
      <Route path="/tools/integrations" element={<Integration />} />

      <Route
        path="/companies/*"
        element={
          <ProtectedRoute isAllowed={haveCompaniesReadPermissions}>
            <CompaniesRouter />
          </ProtectedRoute>
        }
      />

      <Route path="/quick-response/*" element={<QuickResponseRouter />} />
      <Route path="/no-access" element={<NoAccessPage />} />
      <Route path="/not-found" element={<NotFound />} />

      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/settings/notifications" element={<NotificationsPage />} />
      <Route
        path="/settings/general"
        element={
          <ProtectedRoute isAllowed={haveSettingsManagementPermissions}>
            <GeneralSettings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reset-password/perform"
        element={<MessengerResetPassPerfrom />}
      />
      <Route path="/reset-password" element={<ResetPassPage />} />
      <Route path="/verify-email" element={<MessengerVerifyEmail />} />
      <Route path="/invalid-link" element={<MessengerInvalidLink />} />
      <Route path="/registration" element={<RegistrationPage />} />
      <Route path="/registration/:token" element={<RegistrationPage />} />
      <Route
        path="/welcome/registration/:tokenLink"
        list
        element={<RegistrationPage />}
      />
      <Route
        path="/welcome/partner/registration/:partnerToken"
        element={<RegistrationPage />}
      />
      <Route path="/welcome/login/:tokenLink" element={<LoginPage />} />
      <Route
        path="/welcome/partner/login/:partnerToken"
        element={<LoginPage />}
      />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<AllPurchaseRequests />} />
      <Route path="/chat" element={<MessengerPage />} />
      <Route path="/chat/:chatId" element={<MessengerPage />} />
      <Route path="/chat/join/:employeeId" element={<MessengerPage />} />
      {/* <Route path="/task/*" element={<TaskRoutes />} /> */}
      <Route
        path="/employees"
        element={<Navigate to="/employees/list" replace />}
      />
      <Route path="/employees/*" element={<Employees />} />
      <Route
        path="/role-management/*"
        element={
          <ProtectedRoute
            isAllowed={havePermissionToEditRoles}
            redirectPath="/"
            children={<RoleManagement />}
          />
        }
      />
      <Route
        path="/finance"
        element={
          <ProtectedRoute
            isAllowed={haveFinanceReadPermissions}
            redirectPath="/my-company">
            <Finance parentBreadcrumbs={[Breadcrumbs.MyCompany]} />
          </ProtectedRoute>
        }
      />
      <Route path="/profile/*" element={<Profile />} />
      <Route
        path="/my-company"
        element={
          <ProtectedRoute isAllowed={haveCompanyReadPermissions}>
            <MyCompany />
          </ProtectedRoute>
        }
      />
      <Route path="/administration/*" element={<AdministrationRouter />} />
      <Route path="/file-storage/*" element={<FileStorage />} />
      <Route path="/my-office" element={<MyOffice />} />
      <Route
        path="/requests"
        element={<Navigate to="/requests/all" replace />}
      />
      <Route
        path="/requests/share/:requestId"
        element={<PurchaseRequestShare />}
      />
      <Route
        path="requests/all/:requestId"
        element={
          <PurchaseRequest parentBreadcrumbs={[Breadcrumbs.AllPurchases]} />
        }
      />
      <Route path="/requests/my/:requestId/audit" element={<AuditLogPage />} />
      <Route
        path="requests/my-responses/*"
        element={
          <ProtectedRoute
            isAllowed={haveResponsesReadPermissions}
            children={<MyResponses />}
          />
        }
      />
      <Route
        path="/requests/*"
        element={
          <ProtectedRoute
            isAllowed={haveReadPermissionPurchaseRequest}
            children={<Requests />}
          />
        }
      />

      <Route path="/share/*" element={<Share />} />
      <Route path="/dialogs/welcome/:token" element={<PartnerInvite />} />
      <Route path="/feedback" element={<MainFeedbackFrom />} />
      <Route path="/coming-soon" element={<MainComingSoon />} />
      <Route path="/not-permission" element={<MainNotPermission />} />
      <Route path="/clear-data" element={<ClearDataPage />} />
      <Route path="/redirect/:type/:id" element={<RedirectPage />} />
      <Route path="/unsubscribe/:token" element={<SubscriptionRefusal />} />
      <Route path="/needs/*" element={<NeedsRoutes />} />
      <Route path="/accreditation/*" element={<AccreditationRoutes />} />
      <Route path="/qualification/*" element={<QualificationRoutes />} />
      <Route path="/release-notes" element={<ReleaseNotes />} />
      <Route path="/supplier-management" element={<SupplierManagement />} />
      <Route path="/supplier-management/partners" element={<Partners />} />
      <Route path="/warehouses/*" element={<WarehousesRoutes />} />
      <Route path="/marketplace/*" element={<MarketplacePages />} />
      <Route path="/cost-accounting/*" element={<CostAccountingRouter />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

const publicRoutes = [
  '/login',
  '/quick-response',
  '/no-access',
  '/share',
  '/registration',
  '/welcome',
  '/reset-password',
  '/reset-password/perform',
  '/verify-email',
  '/invalid-link',
  '/unsubscribe'
];

function isPublicRoute(currentLocation) {
  return publicRoutes.some((route) => currentLocation.includes(route));
}

export default (props) => {
  const navigate = useNavigate();

  useAutoLockZoomWhenControlIsFocused();

  const interceptor = useCallback(
    () =>
      fetchIntercept.register({
        response(response) {
          const currentLocation = getCurrentLocation();

          if (currentLocation.includes('/login')) {
            return response;
          }

          if (!isPublicRoute(currentLocation)) {
            if (response.status === 401) {
              navigate(`/login?redirectUrl=${currentLocation}`);
            }
          }
          if (response.status === 403) {
            navigate('/no-access', { replace: true });
          }

          if (response.status === 404) {
            navigate('/not-found', { replace: true });
          }

          return response;
        }
      }),
    [navigate]
  );

  return (
    <I18nextProvider i18n={i18n}>
      <TranslateProvider>
        <CatchErrors>
          <ThemeProvider>
            <Provider store={props.store}>
              <LocalProvider history={props.history}>
                <DndProvider backend={HTML5Backend}>
                  <Suspense fallback={<Loader />}>
                    <SnackbarProvider>
                      <Interceptor interceptor={interceptor}>
                        <ConnectionsWrapper>
                          <PopupRoot>
                            <NotificationProvider>
                              <Root>
                                <div
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    overflowX: 'hidden',
                                    position: 'relative',
                                    fontSize: '14px'
                                  }}>
                                  <VideoChatProvider>
                                    <RoutesComponent />
                                  </VideoChatProvider>
                                  <ToastContainer
                                    configurator={toastConfigurator}
                                    autoClose={5000}
                                  />
                                </div>
                              </Root>
                            </NotificationProvider>
                          </PopupRoot>
                        </ConnectionsWrapper>
                      </Interceptor>
                    </SnackbarProvider>
                  </Suspense>
                </DndProvider>
                <GlobalStyles />
              </LocalProvider>
            </Provider>
          </ThemeProvider>
        </CatchErrors>
      </TranslateProvider>
    </I18nextProvider>
  );
};
