import {
  ChangeEvent,
  useCallback,
  useState,
  useMemo,
  KeyboardEvent,
  ClipboardEvent,
  useEffect,
  SyntheticEvent,
  HTMLAttributes
} from 'react';
import cn from 'classnames';
import {
  Autocomplete,
  TextField,
  InputBaseClasses,
  TextFieldProps,
  AutocompleteClasses
} from '@mui/material';
import { isFunction } from '@/lib/utils';
import { Chip } from '../chip';
import { Typography } from '../Typography';
import { Tooltip } from '../Tooltip';
import { Descriptor, TagInputProps } from './tagInput.types';
import styles from './tagInput.module.scss';

const echo = (value: string) =>
  Promise.resolve({ label: value, tooltip: value, valid: true } as Descriptor);

const textFieldClasses = {
  root: styles.textFiled
};

class ComponentPropsBuilder {
  private renderOptionsThrowPortal = false;

  setRenderOptionsThrowPortal(renderOptionsThrowPortal: boolean) {
    this.renderOptionsThrowPortal = renderOptionsThrowPortal;

    return this;
  }

  build() {
    return {
      popper: {
        disablePortal: !this.renderOptionsThrowPortal,
        popperOptions: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 4]
              }
            }
          ]
        }
      }
    };
  }
}

interface NoOptionsToShowProps {
  text?: string;
}
function NoOptionsToShow({ text }: NoOptionsToShowProps) {
  return <Typography variant="body1Reg">{text}</Typography>;
}

interface TagProps {
  item: Descriptor;
  handleDelete: (id: number) => void;
  id: number | string;
}

function Tag({ item, handleDelete, id }: TagProps) {
  return (
    <Tooltip title={item.tooltip} placement="top">
      <Chip
        id={id as any}
        label={item.label}
        onDelete={handleDelete}
        error={!item.valid}
      />
    </Tooltip>
  );
}
export function TagInput({
  initialList,
  match = echo,
  placeholder,
  onChange,
  onInputChange: inputChangeCB,
  onPaste,
  hideInput = false,
  allowedOptions,
  noOptionsText,
  allOptionsSelectedText,
  error,
  errorText,
  className,
  disableSearch = false,
  renderOptionsThrowPortal = false
}: TagInputProps) {
  const [list, setList] = useState<Descriptor[]>(initialList ?? []);
  const [value, setValue] = useState('');

  useEffect(() => setList(initialList ?? []), [initialList]);

  const componentsProps = useMemo(
    () =>
      new ComponentPropsBuilder()
        .setRenderOptionsThrowPortal(renderOptionsThrowPortal)
        .build(),
    [renderOptionsThrowPortal]
  );

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event) {
        if (event.target.value !== ' ') {
          setValue(event.target.value);
          inputChangeCB?.(event.target.value);
        }
      }
    },
    []
  );

  const onAutocompleteInputChange = useCallback(
    (event: SyntheticEvent, updatedValue: string, _reason: string) => {
      if (event) {
        setValue(updatedValue);
        inputChangeCB?.(updatedValue);
      }
    },
    []
  );

  const handleSelectOption = useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      updatedValue: readonly Descriptor[],
      reason?: string
    ) => {
      if (reason !== 'removeOption') {
        const updValue: Descriptor[] = [...updatedValue];
        setList(updValue);
        if (isFunction(onChange)) {
          onChange([...updValue]);
        }
        event.stopPropagation();
        event.preventDefault();
      }
    },
    [onChange]
  );

  const isOptionEqualToValue = useCallback(
    (option: Descriptor, selectedValue: Descriptor) => {
      if (!Number.isNaN(+option.id!) && !Number.isNaN(+selectedValue.id!)) {
        return +option.id! === +selectedValue.id!;
      }
      return option.id === selectedValue.id;
    },
    []
  );

  const extractListItems = useCallback(async () => {
    if (value !== '') {
      const newItem = await match(value);
      setList((prev) => {
        const updatedList = [...prev, newItem];
        if (isFunction(onChange)) {
          onChange(updatedList);
        }
        return updatedList;
      });
      setValue('');
    }
  }, [match, onChange, value]);

  const handleKeyDown = useCallback(
    async (event: KeyboardEvent) => {
      if (['Space', 'Enter'].includes(event.code)) {
        await extractListItems();
      }
    },
    [extractListItems]
  );

  const handleDelete = useCallback(
    (id: number) =>
      setList((prev) => {
        const updatedList = prev.filter((item, index) =>
          item.id ? item.id !== id : index !== id
        );
        if (isFunction(onChange)) {
          onChange(updatedList);
        }
        return updatedList;
      }),
    [onChange]
  );

  const handlePaste = useCallback(
    async (event: ClipboardEvent) => {
      if (isFunction(onPaste)) {
        const copiedText = event.clipboardData.getData('text');
        const newItems = await onPaste(copiedText);
        setList((prev) => {
          if (isFunction(onChange)) {
            onChange([...prev, ...newItems]);
          }
          return [...prev, ...newItems];
        });
      }
      event.preventDefault();
    },
    [onPaste, onChange]
  );

  const inputBaseClasses: Partial<InputBaseClasses> = useMemo(
    () => ({
      input: hideInput
        ? styles.hidden
        : cn(styles.input, list.length === 0 && styles.placeholder),
      focused: styles.inputFocused,
      root: cn(styles.inputRoot, error && styles.error, className)
    }),
    [list.length, hideInput, error, className]
  );

  const tags = useMemo(
    () => (
      <>
        {list.map((item, index) => (
          <Tag
            item={item}
            id={item.id ?? index}
            handleDelete={handleDelete}
            key={item.id ?? index}
          />
        ))}
      </>
    ),
    [list, handleDelete]
  );

  const renderInput = useCallback(
    (params: TextFieldProps) => (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          endAdornment: null
        }}
        placeholder={placeholder}
      />
    ),
    [list, placeholder]
  );

  const renderTags = useCallback(
    (selected: Descriptor[]) =>
      selected.map((descriptor) => (
        <Tag
          key={descriptor.id!}
          item={descriptor}
          handleDelete={handleDelete}
          id={descriptor.id!}
        />
      )),
    [handleDelete]
  );

  const autocompleteClasses: Partial<AutocompleteClasses> = useMemo(
    () => ({
      popper: styles.popper,
      noOptions: styles.noOption,
      input: styles.inputAutocomplete,
      focused: styles.inputFocusedAutocomplete,
      root: cn(
        styles.inputRootAutocomplete,
        (list.some((option) => !option.valid) || error) && styles.error
      )
    }),
    [list]
  );

  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: Descriptor
  ) => (
    <li {...props} key={option.id} className={styles.option}>
      <Typography
        className={styles.optionContent}
        Component="span"
        variant="body1Reg">
        {option!.label}
      </Typography>
    </li>
  );

  return (
    <div>
      {allowedOptions ? (
        <Autocomplete
          renderInput={renderInput}
          options={allowedOptions}
          multiple
          disableClearable
          filterOptions={disableSearch ? (o: Descriptor[]) => o : undefined}
          componentsProps={componentsProps}
          renderTags={renderTags}
          onInputChange={onAutocompleteInputChange}
          onChange={handleSelectOption}
          isOptionEqualToValue={isOptionEqualToValue}
          inputValue={value}
          value={list}
          noOptionsText={
            <NoOptionsToShow
              text={value ? noOptionsText : allOptionsSelectedText}
            />
          }
          filterSelectedOptions
          classes={autocompleteClasses}
          renderOption={renderOption}
        />
      ) : (
        <TextField
          value={value}
          onChange={onInputChange}
          onKeyDown={handleKeyDown}
          onBlur={extractListItems}
          placeholder={list.length === 0 ? placeholder : ''}
          classes={textFieldClasses}
          onPaste={handlePaste}
          InputProps={{
            startAdornment: tags,
            classes: inputBaseClasses
          }}
        />
      )}
      {error && (
        <Typography variant="caption" className={styles.errorText}>
          {errorText}
        </Typography>
      )}
    </div>
  );
}
