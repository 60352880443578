import { MouseEvent } from 'react';
import { Icon } from '@/components/Icon';
import cn from 'classnames';
import styles from './ButtonIcon.module.scss';
import { ButtonIconProps } from './ButtonIcon.types';

export function ButtonIcon({
  iconName,
  variant = 'large',
  onClick,
  className = '',
  disabled = false,
  stopPropagation = false,
  active = false
}: ButtonIconProps) {
  const handleClickButton = (e: MouseEvent) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <button
      type="button"
      onClick={handleClickButton}
      disabled={disabled}
      className={cn(
        styles.button,
        styles[variant],
        className,
        disabled && styles.disabled,
        active && styles[`${variant}Active`]
      )}
      data-testid="button-icon">
      <Icon iconName={iconName} />
    </button>
  );
}
