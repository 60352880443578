import cn from 'classnames';
import styles from './Loader.module.scss';
import { LoaderProps } from './Loader.types';

export function Loader({ className }: LoaderProps) {
  return (
    <div className={cn(styles.loader, className)}>
      <div className={styles.dots}>
        <hr className={styles.dot} />
        <hr className={styles.dot} />
        <hr className={styles.dot} />
        <hr className={styles.dot} />
      </div>
    </div>
  );
}
